<template>
  <div class="coinater">
    <div class="fdleft">
      <!-- <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">形式审查</div>
          <div class="fdtsec">{{ form.name }}</div>
        </div>
      </div> -->
      <div class="fdlcont">
        <div class="fdltitle">申报信息汇总表</div>
        <div class="fdltxt">
          <div class="table">
            <el-form
              ref="form"
              :model="form"
              label-width="150px"
              :label-position="labelPosition"
            >
              <el-row :gutter="50">
                <el-col :span="12">
                  <div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="项目名称"
                        prop="name"
                        :rules="{
                          required: true,
                          message: '请填写项目名称',
                          trigger: 'blur',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.name"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="所属地区"
                        prop="city"
                        :rules="{
                          required: true,
                          message: '请选择所属地区',
                          trigger: 'change',
                        }"
                      >
                        <el-select
                          :disabled="disabledEdit"
                          @change="cityChange"
                          v-model="form.city"
                          placeholder="请选择所属地区"
                        >
                          <el-option
                            v-for="item in cityOptions"
                            :key="item.name"
                            :label="item.name"
                            :value="item.name"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item
                          label="所属区县"
                          prop="county"
                          :rules="{
                            required: true,
                            message: '请选择所属区县',
                            trigger: 'change',
                          }"
                        >
                          <el-select
                            :disabled="disabledEdit"
                            v-model="form.county"
                            placeholder="请选择所属区县"
                          >
                            <el-option
                              v-for="item in countyOptions"
                              :key="item"
                              :label="item"
                              :value="item"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="建筑面积（m²）"
                        prop="building_area"
                        :rules="{
                          required: true,
                          message: '请填写建筑面积',
                          trigger: 'blur',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.building_area"
                          placeholder="保留2位小数"
                        ></el-input>
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item
                          label="占地面积（m²）"
                          prop="area"
                          :rules="{
                            required: true,
                            message: '请填写占地面积',
                            trigger: 'blur',
                          }"
                        >
                          <el-input
                            :disabled="disabledEdit"
                            v-model="form.area"
                            placeholder="保留2位小数"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="测评阶段"
                        prop="stage"
                        :rules="{
                          required: true,
                          message: '请选择测评阶段',
                          trigger: 'change',
                        }"
                      >
                        <el-radio-group
                          :disabled="disabledEdit"
                          v-model="form.stage"
                        >
                          <el-radio :label="1">建筑能效测评</el-radio>
                          <el-radio :label="2">建筑能效实测评估</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="测评单位"
                        prop="test_company"
                        :rules="{
                          required: true,
                          message: '请填写测评单位',
                          trigger: 'blur',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.test_company"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="测评联系人"
                        prop="test_contact"
                        :rules="{
                          required: true,
                          message: '请填写测评联系人',
                          trigger: 'blur',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.test_contact"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="测评联系人电话"
                        prop="test_tel"
                        :rules="{
                          required: true,
                          message: '请填写测评联系人电话',
                          trigger: 'blur',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.test_tel"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="测评时间"
                        prop="test_time"
                        :rules="{
                          required: true,
                          message: '请填写测评时间',
                          trigger: 'change',
                        }"
                      >
                        <el-date-picker
                          type="date"
                          :disabled="disabledEdit"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期"
                          v-model="form.test_time"
                        ></el-date-picker>
                      </el-form-item>
                    </div>

                  </div>
                </el-col>
                <el-col :span="12"
                  ><div class="grid-content bg-purple-light">
                    <div class="gezi name_input">
                      <el-form-item
                        label="项目地址"
                        prop="address"
                        :rules="{
                          required: true,
                          message: '请填写项目地址',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.address"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="建筑类型"
                        prop="building_type"
                        :rules="{
                          required: true,
                          message: '请选择建筑类型',
                          trigger: 'change',
                        }"
                      >
                        <el-select
                          :disabled="disabledEdit"
                          v-model="form.building_type"
                          placeholder="请选择建筑类型"
                        >
                          <el-option label="公共建筑" :value="1"></el-option>
                          <el-option label="居住建筑" :value="2"></el-option>
                        </el-select>
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item
                          label="建筑功能"
                          prop="building_ability"
                          :rules="{
                            required: true,
                            message: '请选择建筑功能',
                            trigger: 'change',
                          }"
                        >
                          <el-select
                            :disabled="disabledEdit"
                            v-if="form.building_type == 1"
                            v-model="form.building_ability"
                            placeholder="请选择建筑功能"
                          >
                            <el-option
                              v-for="item in weatherOptions"
                              :key="item.name"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>

                          <el-select
                            v-else
                            :disabled="disabledEdit"
                            v-model="form.building_ability"
                            placeholder="请选择建筑功能"
                          >
                            <el-option
                              v-for="item in juzhuOptions"
                              :key="item.name"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="层数"
                        prop="floor"
                        :rules="{
                          required: true,
                          message: '请填写层数',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.floor"
                          placeholder="实例：地上3层，地下3层"
                        ></el-input>
                      </el-form-item>
                      <div class="geziright">
                        <el-form-item
                          label="气候区域"
                          prop="weather"
                          :rules="{
                            required: true,
                            message: '请选择气候区域',
                            trigger: 'change',
                          }"
                        >
                          <el-select
                            :disabled="disabledEdit"
                            v-model="form.weather"
                            placeholder="请选择气候区域"
                          >
                            <el-option label="夏热冬冷" value="1"></el-option>
                            <el-option label="寒冷" value="2"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="gezi">
                      <el-form-item
                        label="测评星级"
                        prop="stars"
                        :rules="{
                          required: true,
                          message: '请填写测评星级',
                          trigger: 'change',
                        }"
                      >
                        <el-radio-group
                          :disabled="disabledEdit"
                          v-model="form.stars"
                        >
                          <el-radio :label="1"
                            ><i class="el-icon-star-on"></i
                          ></el-radio>
                          <el-radio :label="2"
                            ><i class="el-icon-star-on"></i
                            ><i class="el-icon-star-on"></i
                          ></el-radio>
                          <el-radio :label="3"
                            ><i class="el-icon-star-on"></i
                            ><i class="el-icon-star-on"></i
                            ><i class="el-icon-star-on"></i
                          ></el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="建设单位"
                        prop="build_company"
                        :rules="{
                          required: true,
                          message: '请填写建设单位',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.build_company"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="项目负责人"
                        prop="manger"
                        :rules="{
                          required: true,
                          message: '请填写项目负责人',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.manger"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="项目联系人"
                        prop="contact"
                        :rules="{
                          required: true,
                          message: '请填写项目联系人',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.contact"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        label="项目联系人电话"
                        prop="contact_tel"
                        :rules="{
                          required: true,
                          message: '请填写项目联系人电话',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          :disabled="disabledEdit"
                          v-model="form.contact_tel"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="16">
                  <div class="gezi name_input">
                    <div v-if="disabledEdit">
                      <el-form-item label="承诺函">
                        <div v-if="fileList && fileList.length>0">
                          <div @click="downloadFile(file)"  style="color:#409EFF;cursor: pointer;" v-for="file in fileList" :key="file.id" >
                            {{file.file}}
                          </div>
                        </div>
                        <div v-else>
                          承诺函未上传
                        </div>
                      </el-form-item>
                    </div>

                     <div v-else>
                       <el-form-item label="上传承诺函">
                         <el-upload
                             class="upload-demo"
                             action="https://api.gbservice.cn/api/public/bupload/"
                             :file-list="fileList"
                             :on-success="fileUploadSuccess"
                         >
                         
                           <el-button size="small" type="primary"
                           >点击上传</el-button
                           >
                            <span
                           style="
                          font-size: 14px;
                        "
                       > 选择上传文件 </span
                       >
                       <span style="color: red;">提示：请加盖建设单位和测评单位公章</span>
                         </el-upload>
                        
                       </el-form-item>
                      
                       <div class="fileright">
                         <a
                             href="/江苏省民用建筑能效标识申报承诺书.docx"
                             download="江苏省民用建筑能效标识申报承诺书.docx"
                             style="color: #3086fb; text-decoration: none"
                         >承诺函模板点击下载</a
                         >
                       </div>
                     </div>
                    </div>
                    <div class="gezi name_input">
                      <el-form-item
                        v-if="disabledEdit"
                        :rules="{
                          required: true,
                          message: '外观图',
                          trigger: 'change',
                        }"
                        label="外观图"
                        prop="test_time"
                      >
                        <div v-for="(item, index) in photo" :key="index">
                          <div
                            style="
                              height: 146px;
                              width: 146px;
                              border: 1px solid #000;
                            "
                          >
                            <img
                              :src="item.url"
                              alt=""
                              style="height: 100%; width: 100%"
                            />
                          </div>
                        </div>
                      </el-form-item>

                      <el-form-item v-else label="上传外观文件图">
                        <el-upload
                          action="https://api.gbservice.cn/api/public/bupload/"
                          list-type="picture-card"
                          :file-list="photo"
                          :on-success="photo_success"
                          :on-remove="handleRemovePhoto"
                        >
                          <i class="el-icon-plus"></i>
                          <!--                    <div slot="file" slot-scope="{ file }">-->
                          <!--                      <img-->
                          <!--                        class="el-upload-list__item-thumbnail"-->
                          <!--                        :src="file.url"-->
                          <!--                        alt=""-->
                          <!--                      />-->
                          <!--                    </div>-->
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                          <img width="100%" :src="dialogImageUrl" alt="" />
                        </el-dialog>
                      </el-form-item>
                    </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="fdright">
      <div class="fdtitlee">
        <div class="fdtsec">{{ form.name }}</div>
      </div>
      <div class="fdbacck">
        <el-button @click="goBack" type="primary">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">审查意见</div>
        <div class="fdrtxt">
          <el-input
            disabled
            v-model="idea"
            placeholder="请输入内容"
            style="height: 513px; border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group v-model="pass" disabled>
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <!-- <el-button type="primary">上一页</el-button> -->
          <el-button
            type="primary"
            :disabled="disabledEdit"
            @click="saveProject"
            >保存</el-button
          >
          <el-button type="primary" @click="next_page">下一页</el-button>
        </div>
      </div>
    </div>

    <el-dialog
        title="文件预览"
        :visible.sync="showFile"
        width="80%">
      <div class="fdltxt">
        <iframe :src="fileUrl+'?#toolbar=0'" style="width: 100%; height:76vh"></iframe>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { projectTableInfo, storeProject } from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";

export default {
  data() {
    return {
      fileUrl: '',
      ifreamShow: true,
      showFile: false,
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      formfiles: [],
      form: {
        stars: 1,
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      //公共建筑
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],
      //居住建筑
      juzhuOptions: [
        {
          name: "住宅",
          id: 1,
        },
        {
          name: "基地",
          id: 2,
        },
      ],

      photo: [],
      all_data: {},
      project_id: "",

      idea: "",
      pass: "",

      disabledEdit: true,
    };
  },
  mounted() {
    let project_id = this.$route.query.project_id;
    if (project_id && project_id != "") {
      this.project_id = project_id;
      this.getInfo();
    }
  },
  methods: {

    downloadFile(row){
      let url = row.url;
      if (url) {
        let file_type = url.split('.');
        this.fileUrl = url;
        if (file_type[file_type.length - 1] == 'docx') {
          let a = document.createElement('a')
          a.href =url;
          a.click();
        } else {
          this.showFile = true;
        }
      }

    },

    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        let file1 = [];
        let file2 = [];
        if (this.form.files && this.form.files.length > 0) {
          file1 = this.form.files
            .map((item) => {
              if (item.type == 1) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.fileList = file1;
        if (this.form.files && this.form.files.length > 0) {
          file2 = this.form.files
            .map((item) => {
              if (item.type == 2) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.photo = file2;

        let pre_files = this.form.files;
        let tmp_files = [];
        for (let i = 0; i < pre_files.length; i++) {
          if (pre_files[i].type != 1 && pre_files[i].type != 2) {
            tmp_files.push(pre_files[i]);
          }
        }
        this.tmp_files = tmp_files;
        if (
          that.form.status == 15 ||
          that.form.status == 16 ||
          that.form.status == 17 ||
          that.form.status == 18
        ) {
          this.disabledEdit = false;
        }
        let checks = res.data.checks;
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 3) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.project.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
      });
    },

    //返回
    goBack() {
      this.$router.push({
        path: "./ipImformation?id=" + this.project_id,
      });
      // this.$router.back()
    },

    //下一页
    next_page() {
      this.$router.push({
        path: "./ipTotaltable?project_id=" + this.project_id,
      });
    },

    fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList);
    },
    //图片上传成功
    photo_success(re, file, fileList) {
      this.photo = fileList.map((item) => {
        return item.response.data;
      });
    },
    handleRemovePhoto(file, fileList) {
      if (fileList.length > 0) {
        this.photo = fileList.map((item) => {
          return item.response.data;
        });
      } else {
        this.photo = [];
      }
    },

    //切换市
    cityChange(city) {
      for (let i = 0; i < this.cityOptions.length; i++) {
        if (this.cityOptions[i].name == city) {
          this.countyOptions = this.cityOptions[i].area;
          break;
        }
      }
    },

    //保存项目
    saveProject() {
      let that = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = this.form;
          params.files = [];
          if (this.fileList && this.fileList.length > 0) {
            params.files = this.fileList.map((item) => {
              item.type = 1;
              item.eid = undefined;
              item.file = undefined;
              item.id = undefined;
              item.status = undefined;
              item.uid = undefined;
              return item;
            });
          }
          if (this.photo && this.photo.length > 0) {
            for (let i = 0; i < this.photo.length; i++) {
              this.photo[i].type = 2;
              this.photo[i].eid = undefined;
              this.photo[i].file = undefined;
              this.photo[i].id = undefined;
              this.photo[i].status = undefined;
              this.photo[i].uid = undefined;
            }
            params.files = params.files.concat(this.photo);
          }

          console.log(this.tmp_files);
          if (this.tmp_files && this.tmp_files.length > 0) {
            params.files = params.files.concat(this.tmp_files);
          }
          if (params.files && params.files.length > 0) {
            for (let i = 0; i < params.files.length; i++) {
              if (!params.files[i].name) {
                params.files[i].name = params.files[i].url;
              }
              params.files[i].eid = undefined;
              params.files[i].file = undefined;
              params.files[i].id = undefined;
              params.files[i].status = undefined;
              params.files[i].uid = undefined;
            }
          }
          params.token = this.$store.state.token;
          params.created_at = undefined;
          params.updated_at = undefined;
          params.checks = undefined;
          params.log = undefined;
          // params.files = undefined;
          params.jizhu = undefined;
          params.zname = undefined;
          params.detail = undefined;
          params.report = undefined;
          params.send_address = undefined;
          storeProject(params)
            .then((res) => {
              console.log(res);
              that.$message.success("保存成功");
              // that.$router.push({
              //   path: './ipImformation?id='+that.project_id
              // })
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      console.log(this.fileList);
    },
    handleRemove(file) {
      console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "1");
    },
  },
};
</script>
<style scoped>
/deep/.table .el-form-item {
  margin-bottom: 30px;
}
/deep/.el-textarea > textarea {
  height: 513px;
}
.table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style lang="scss" scoped>
.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 35px;
        height: 76vh;
        overflow-y: scroll;
      }
      .fdltxt::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;
    .fdtsec {
      display: flex;
      flex-direction: row-reverse;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #666666;
    }
    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 27px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin-bottom: 34px;
      }

      .fdradio {
        margin: 15px 0;
        text-align: center;
      }

      .fdrbtn {
        // margin: 20px 0;
        text-align: center;
      }
    }
  }
}
.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .geziright {
    // margin-left: 20px;
  }
  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
    margin-top: 12px;
  }
}
</style>
